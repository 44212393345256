<template>
  <div>
    <van-nav-bar
      title="情景训练场"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="traininggroundItem">
      <div class="title">
        <div class="ctx-title">
          <div class="title-ctx">你选择</div>
          <div class="title-fk"></div>
        </div>
        <img
          class=""
          src="https://lesson.iapeap.com/images/EmotionalValue/xuanze.png"
          alt=""
        />
      </div>
      <div class="content">
        <div class="contenttitle">{{ option }}</div>
        <div class="contentctx" v-html="formattedText"></div>
        <div class="content2">
          <div class="ctx2">场景解析</div>
          <div class="ctx3" v-html="formattedText2"></div>
        </div>
        <div class="content2">
          <div class="ctx2">建议</div>
          <div class="ctx3" v-html="formattedText3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { memberLog } from "../../api/login";

export default {
  data() {
    return {
      option_info: "",
      option: "",
      option_info2: "",
      option_info3: "",
      starttime: "",
      endtime: "",
      time: "",
    };
  },
  computed: {
    formattedText() {
      return this.option_info.replace(/\n/g, "<br>");
    },
    formattedText2() {
      return this.option_info2.replace(/\n/g, "<br>");
    },
    formattedText3() {
      return this.option_info3.replace(/\r\n/g, "<br><br>");
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.option = this.$route.query.item.option;
    this.option_info = this.$route.query.item.option_info;
    this.option_info2 = this.$route.query.item.option_info2;
    this.option_info3 = this.$route.query.item.option_info3;

    this.timer = setInterval(() => {
      this.time++;
      // console.log("页面累积浏览时间是：",this.time,"秒")
    }, 1000); // 每秒触发一次
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    this.starttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("进入页面的系统时间是：", this.starttime);
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    this.endttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("离开页面的系统时间是", this.endttime);

    //把参数传给后端接口，starttime、endttime、time
    let data = {
      startTime: this.starttime,
      endTime: this.endttime,
      userId: window.localStorage.getItem("userId"),
      pageName: "情景训练场选择的答案："+this.option,
      timeStamp: this.time,
    };
    memberLog(data).then((res) => {});
  },
};
</script>

<style lang="less">
.navbar {
  background-color: #ededed;
}
/deep/ .van-nav-bar .van-icon {
  color: #464646;
}
.traininggroundItem {
  padding: 20px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ctx-title {
      .title-ctx {
        z-index: 999;
        position: relative;
        font-size: 18px;
        font-weight: 500;
        color: #000025;
        line-height: 25px;
      }
      .title-fk {
        width: 35px;
        height: 5px;
        background: #629ee9;
        position: relative;
        bottom: 8px;
        z-index: 998;
        transform: skew(-25deg);
      }
    }
    img {
      width: 51px;
      height: 57px;
    }
  }
  .content {
    padding: 20px 20px 20px 20px;
    box-sizing: border-boxc;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    border-radius: 8px;
    .contenttitle {
      // width: 268px;
      min-height: 40px;
      background: #d3e3f7;
      box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
      border-radius: 8px;
      margin: 0 auto;
      line-height: 40px;
      padding: 0 20px;
      box-sizing: border-box;
      color: #629ee9;
      font-size: 12px;
      text-align: left;
    }
    .contentctx {
      margin-top: 20px;
      font-size: 12px;
      width: 299px;
      font-size: 13px;
      font-weight: 400;
      color: #000025;
      line-height: 18px;
      letter-spacing: 1px;
      text-indent: 20px;
      padding: 0 15px;
      box-sizing: border-box;
    }
  }
  .content2 {
    width: 296px;
    background: #f6f9fd;
    border-radius: 8px;
    margin-top: 20px;
    .ctx2 {
      width: 92px;
      height: 27px;
      background: #629ee9;
      border-radius: 8px 0px 8px 0px;
      color: #fff;
      text-align: center;
      font-size: 13px;
      font-weight: 500;
      color: #ffffff;
      line-height: 27px;
    }
    .ctx3 {
      margin: 20px;
      padding-bottom: 20px;
      font-size: 13px;
      font-weight: 300;
      color: #000025;
      line-height: 18px;
    }
    .ctx4 {
      margin: 20px;
      font-size: 13px;
      font-weight: 500;
      color: #629ee9;
      line-height: 18px;
    }
  }
}
</style>
